import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MainServiceService } from 'src/app/services/main-service.service';

@Injectable({
  providedIn: 'root'
})
export class MTicketService {

  constructor(
    private http: HttpClient,
    private mainService: MainServiceService
  ) { }

  verificaDisponibilita(formValue: any) {
    const body = formValue;
    return this.http.post<any>(this.mainService.SERVER_URL + '&action=getDisponibilita' , body);
  }

  getTariffe(idCalendario: number) {
    return this.http.post<any>(this.mainService.SERVER_URL + '&action=getTariffe' , {idCalendario});
  }


  getConfig(): Observable<any> {
    const url = this.mainService.SERVER_URL + `&action=mTicketConfig&lang=` + this.mainService.language;
    return this.http.get<any>(url)
  }

  recuperaQrCode(formValue: any) {
    const body = formValue;
    return this.http.post<any>(this.mainService.SERVER_URL + '&action=recuperaQrCode' , body);
  }

}
